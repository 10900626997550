<template>
	<div class="page_box" v-loading="loading">
	 
		<div class="bom_box">
			<div class="bom_btn_flex">
				 {{page_title}}
			 
			</div>
			<div class="form_box">
				<div class="fomr_flex">
					<div class="lefts"><span>*</span>福利下发名称</div>
					<div class="rights">
						<div class="inputs1">
							<el-input v-model="forms.title" placeholder="请输入福利下发名称" ></el-input>
						</div>
					</div>
				</div>
				<div class="fomr_flex">
					<div class="lefts"><span>*</span>选择日期</div>
					<div class="rights">
						<div class="inputs2">
							<el-date-picker v-model="forms.date" :clearable="false" value-format="yyyy-MM-dd" type="month" :disabled="$route.query.time?true:false"
							placeholder="请选择月份" :editable="false"  ></el-date-picker>
						</div>
					</div>
				</div>
				<div class="fomr_flex">
					<div class="lefts"><span>*</span>选择部门</div>
					<div class="rights">
						<div class="inputs3">
							 
							<bumen_cascader ref="bumen_cascader" :multiple="false" :is_show_position="false"
						@confirm="confirm_bumen_cascader_value" />
						</div>
					</div>
				</div>
				<div class="fomr_flex">
					<div class="lefts"><span>*</span>选择福利</div>
					<div class="rights">
						<div class="inputs4">
							<el-select v-model="forms.id" placeholder="请选择">
								<el-option
								v-for="(item,index) in lp_list"
								:key="item.id"
								:label="item.title"
								:value="item.id">
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
				 
				<div class="fomr_flex">
					<div class="lefts"> </div>
					<div class="rights">
						<div class="btn_flex">
							<div class="btn1" @click="handle_tj">提交</div>
							<div class="btn2" @click="handle_qx">取消</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	 
	</div>
</template>
<script>
 

export default {
	components: {
	 
	},
	data() {
		return {
			loading: false, //加载总
			tab_show: false,
			page_title: "", //页面名称  来之路由
			check_list: [], //选中的
			//提交数据
			forms: {
				title:'',//福利名称
				date:'',//日期
				depart_id:'',//部门id
				id:'',//福利id
			},
			bm_list: [],//部门
			bm_list_ac: [],//部门选中
		 
		  
			times: [], //时间范围
			lp_list:[],
		 
		};
	},
	 
	methods: {
		//提交
		handle_tj(){
			// this.$refs.bumen_cascader.init({
			// 	type: '设置数据',
			// 	value: [],
			// })
			if(!this.forms.title){
				alertErr('请输入福利下发名称')
				return
			}
			if(!this.forms.date){
				alertErr('请选择月份')
				return
			}
			if(this.bm_list_ac.length<3){
				alertErr('请选择部门')
				return
			}
			if(!this.forms.id){
				alertErr('请选择福利')
				return
			}
			 
			this.forms.depart_id=this.bm_list_ac[this.bm_list_ac.length-1]
			console.log('forms',this.forms)

			this.loading = true;
			this.$api("createGiftSend", {
				...this.forms,
			}, "post").then((res) => {
				this.loading = false;
				if (res.code == 200) {
					this.$router.push({
						path:'/Gift_distributed',
					})
				} else {
					alertErr(res.msg);
				}
			});



		},
		handle_qx(){
			this.$router.push({
						path:'/Gift_distributed',
					})
		},
		confirm_bumen_cascader_value(value, bumen_items, bumen_titles) {
			this.$log('勾选部门数据', value)
			this.bm_list_ac=value
		},
		
		get_lp_list(){
			
			this.loading = true;
			this.$api("giftList", {
				page:1,
				limit:10000000,
			 
			}, "post").then((res) => {
				this.loading = false;
				if (res.code == 200) {
					 this.lp_list=res.data.list
				} else {
					alertErr(res.msg);
				}
			});
		},
		 
	 
	 
		 
	 
		 
	 
	},
	computed: {},
	watch: {},
	created() {
		this.page_title = this.$route.meta.title;
		let time=new Date().getFullYear()+'-'+((new Date().getMonth()+1)>10?'':'0')+(new Date().getMonth()+1)
		this.forms.date = time
		// this.get_bm_list()
	 
		this.get_lp_list()
		
	},
	mounted() {
		

	 },
};
</script>

<style scoped lang="less">


.page_box {
	height: 100%;
	background: #ffffff;

	.bom_box {
		.bom_btn_flex {
			width: 100%;
			height: 51px;
			padding:  20px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: bold;
			font-size: 14px;
			color: #333333;
		}
		.form_box{
			padding-left: 55px;
			.fomr_flex{
				display: flex;
				// align-items: center;
				margin-bottom: 16px;
				.lefts{
					width: 130px;
					height: 40px;
					line-height: 40px;
					flex-shrink: 0;
					text-align: right;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;
					padding-right: 10px;
					span{
						font-weight: 400;
						font-size: 14px;
						color: #FF0000;
						margin-right: 10px;
					}
				}
				.rights{
					.inputs1{
						width: 897px;
						height: 40px;
						// background: #FFFFFF;
						// border-radius: 0px 0px 0px 0px;
						// border: 1px solid #E8E8E8;
					}
					.inputs2{
						width: 897px;
						height: 40px;
						/deep/.el-date-editor{
							width: 897px;
							height: 40px;
						}
					}
					.inputs3{
						width: 897px;
						height: 40px;
						/deep/.el-cascader{
							width: 897px;
							height: 40px;
						}
					}
					.inputs4{
						width: 897px;
						height: 40px;
						/deep/.el-select{
							width: 897px;
							height: 40px;
						}
					}
					.btn_flex{
						display: flex;
						align-items: center;
						.btn1{
							width: 179px;
							height: 44px;
							background: #2373C8;
							margin-right: 18px;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							text-align: center;
							line-height: 44px;
							cursor: pointer;
						}
						.btn2{
							width: 179px;
							height: 44px;
							border-radius: 4px 4px 4px 4px;
							border: 1px solid #9D9D9D;
							text-align: center;
							line-height: 44px;
							cursor: pointer;			
							font-weight: 400;
							font-size: 16px;
							color: #4A4A4A;

						}
					}
				}
			}	
		}






		.filter-box {
			padding: 30px 20px;
			padding-bottom: 0;

			.filter-group {
				margin-bottom: 10px;
				display: flex;
				align-items: flex-start;

				.filter-label {
					min-width: 100px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;
				}

				.filter-items {
					flex: 1;
					overflow: hidden;

					.el-checkbox {
						margin-bottom: 20px;
					}
				}
			}
		}
	}
}
</style>